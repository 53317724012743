section.home3-main-section .content-box h1 {
    font-size: 48px !important;
    font-weight: 700 !important;
    text-transform: capitalize;
    background-color: #000000;
    background-image: linear-gradient(45deg, #2E4E9A, #1CA180);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}

.home3-cta a {
    padding: 20px 30px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    background: #000;
}

h3.Get {
    font-size: 18px !important;
}

section.home3-main-section {
    padding-top: 60px;
    padding-bottom: 80px;
}


section.home3-main-section .bootom-bnr {
    margin-top: 70px;
}

img.cal03 {
    width: 29px;
    margin-right: 10px;
}

img.girlcar {
    border-radius: 10px;
    margin-bottom: 40px;
    width: 100%;
}

ul.third-row {
    list-style: none;
    margin: 0 auto;
    display: inline-flex;
    margin-top: 60px;
    gap: 30px;
}

section.home3-main-section a.contacted {
    color: #365594;
    font-weight: 500;
}

.arrow02 {
    margin-top: -92px;
    margin-bottom: 10px;
}

.home3-cta1 a {
    background-image: linear-gradient(45deg, #2E4E9A, #1CA180);
    padding: 20px 30px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
}

@media (max-width: 767px) and (min-width: 320px) {
    h3.Dedicated-supoort:before {
        display: none;
    }

    h3.Dedicated-supoort::after {
        display: none;
    }

    .home5-bnr-content {
        margin-top: 20px;
        padding: 20px 10px;
    }

    section.home5-section {
        padding-bottom: 40px;
    }

    .home2-cta1-content {
        padding: 30px 15px 40px;
    }

    .claim-list ul span {
        display: none;
    }

    .claim-list ul {
        list-style: none;
        padding-left: 0px;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }

    .home5-bnr-content h1 {
        font-size: 28px !important;
    }

    h3.Dedicated-supoort {
        max-width: 100%;
        font-size: 22px !important;
    }

    ul.home5-ul {
        display: block;
    }

    section.home3-main-section .content-box h1 {
        font-size: 26px !important;
    }

    section.home3-main-section {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    .arrow02 {
        margin-top: -51px;
        margin-bottom: 0;
    }

    ul.third-row {
        list-style: none;
        margin: 0 auto;
        display: block;
        margin-top: 31px;
        gap: 0;
        padding-left: 0px;
    }

    .home3-cta1 a {
        padding: 18px 19px;
        font-size: 16px;
    }

    .home3-cta {
        margin-top: 30px;
    }

    .home3-cta a {
        padding: 18px 19px;
        font-size: 16px;
    }
}


@media (max-width: 991px) and (min-width: 768px) {
    .home5-bnr-content {
        margin-top: -43px;
        margin-bottom: 40px;
        padding: 20px;
    }

    .home5-bnr-content p.text-white {
        width: 80%;
        margin: 0 auto;
    }

    .claim-list ul span {
        margin-left: -10px;
    }

    .home2-cta1-content {
        padding: 44px 18px;
    }

    section.home3-main-section .content-box h1 {
        font-size: 35px !important;
    }

    p.home2-colpyy {
        float: none;
        color: #fff;
        font-size: 14px;
        text-align: center;
        border-top: 0px !important;
        padding-top: 0px;
    }

}